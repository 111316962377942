import React, { useState } from 'react';
import { API } from "../../utils";

function UploadPhoto() {    
    const [url, setUrl] = useState('');
    const [photo, setPhoto] = useState(null);
    const [description, setDescription] = useState('');
    const [isUrl, setIsUrl] = useState(false); // Pour déterminer si l'utilisateur entre une URL

    const handleFileChange = (e) => {
        setPhoto(e.target.files[0]);
        setUrl(''); // Réinitialiser l'URL si un fichier est sélectionné
        setIsUrl(false); // Basculer sur le mode upload
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
        setPhoto(null); // Réinitialiser le fichier si une URL est saisie
        setIsUrl(true); // Basculer sur le mode URL
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        if (isUrl) {
            formData.append('url', url); // Ajouter l'URL au formulaire
        } else {
            formData.append('photo', photo); // Ajouter le fichier photo
        }
        formData.append('description', description);

        try {
            const response = await API.planificates.uploadPhoto(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading photo:', error);
        }
    };

    return (
        <div>
            <h1>Upload Photo</h1>
            <form onSubmit={handleSubmit}>
                {isUrl ? (
                    <input
                        type="text"
                        value={url}
                        onChange={handleUrlChange}
                        placeholder="Enter Image URL"
                    />
                ) : (
                    <input type="file" onChange={handleFileChange} />
                )}
                <button type="button" onClick={() => setIsUrl(!isUrl)}>
                    {isUrl ? "Upload a Photo" : "Use an Image URL"}
                </button>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadPhoto;
