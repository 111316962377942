import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as TextLogo } from "../images/svg/icon_text_logo.svg";
import { ReactComponent as IconLogo } from "../images/svg/icon_mark_logo.svg";

import { SocialIcon, AppContext } from "../utils";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ddarrowUp from "../images/images/ddArrowUp.svg";
import ddarrowDown from "../images/images/ddArrowDown.svg";
import plusCircleIcon from "../images/Plus-circle.png";

import { InputText } from "primereact/inputtext";


let socialPage = null;

const PagesList = ({
  type,
  pages = [],
  onSelectPage,
  tab,
  visible,
  onVisibleChange,
}) => {
  const [state, setState] = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState("");

  // Filtrer les pages en fonction du terme de recherche
  const filteredPages = pages?.filter((el) =>
    el.page && el.page.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Fonction pour effacer le champ de recherche
  const clearSearch = () => {
    setSearchTerm("");
  };

  //console.log('PagesList =>', state.pages);
  let pageArray = state?.pages;
  let socialTypeCount = {};
  if (pageArray) {
    for (let i = 0; i < pageArray.length; i++) {
      let socialType = pageArray[i].social_type;

      if (socialType in socialTypeCount) {
        socialTypeCount[socialType]++;
      } else {
        socialTypeCount[socialType] = 1;
      }
    }
  }
  //console.log('socialTypeCount =>', socialTypeCount);

  // const [fpages, setFpages] = useState([pages]);
  // const [visible, setVisible] = useState(show);
  //console.log("visible", visible);

  const current_page = state?.currentPage?.page_id;
  const social_type = state?.currentPage?.social_type;
  //console.log('social_type =>', state);

  return (
    <div className="flex flex-col mt-12 items-center lg:items-start ml-4 mr-4 my-pages">
      <div className={`${visible ? "bg-[#f9fafb] py-8" : ""} w-full rounded-[8px] px-4`}>
        <div
          onClick={() => onVisibleChange(!visible)}
          className="flex cursor-pointer"
        >
          <div className="flex gap-3 items-center w-full">
            <div className="SocialIcon grayscale">
              <SocialIcon type={type} className="w-6 h-6" small />
            </div>
            <h2
              className={`${
                !state.sideBarVisible && "hidden"
              } text-[#1d2939] font-bold text-[16px] capitalize`}
            >
              {type}
            </h2>
            <div className="countPage text-[#344054] font-bold text-[12px] bg-[#f2f4f7] px-4 py-2 rounded-[16px] ml-auto">
              {socialTypeCount[type]}
            </div>
            <img
              className={`${!state.sideBarVisible && "hidden"} ${
                type === "facebook"
                  ? "ml-1"
                  : type === "instagram"
                  ? "ml-0"
                  : "ml-3"
              } ml-auto grayscale`}
              src={visible ? ddarrowDown : ddarrowUp}
              alt=""
            />
          </div>
        </div>

        {visible && (
          <div className="mt-4">            
            <span className="p-input-icon-left w-full border border-[#d0d5dd] rounded-[6px]">
              <i className="pi pi-search" />
              <InputText
                value={searchTerm}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                className="text-[#667085] text-[16px] placeholder:text-[#667085] w-full py-2 px-8"
              />

              {searchTerm && (
                <i
                  onClick={clearSearch} 
                  className="pi pi-times text-[#667085] right-[5px] border-l-[1px] pl-1 border-[#667085] cursor-pointer" 
                />
              )}
            </span>
            <div className="social-container flex flex-col overflow-x-auto w-full md:max-h-96 mt-9">
              <ul className="flex flex-col">
                {filteredPages.map((el) => (
                  <li
                    onClick={() => onSelectPage(el)}
                    key={el.id}
                    className={`block w-full px-6 rounded-[6px] py-3 cursor-pointer ${
                      current_page === el.page?.id ? "bg-[#f2f4f7]" : ""
                    } hover:bg-[#f2f4f7]`}
                  >
                    <div className="flex items-center gap-3 w-full">
                      {el.page && el.page.picture && (
                        <img
                          className="w-8 h-8 rounded-xl"
                          src={el.page.picture}
                          alt=""
                        />
                      )}
                      <h3
                        className={`${
                          !state.sideBarVisible && "hidden"
                        } ${
                          current_page === el.page?.id
                            ? "text-[#6941c6]"
                            : ""
                        } font-semibold text-[#1d2939] text-[14px]`}
                      >
                        {el.page && el.page.name}
                      </h3>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SideBar = () => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useContext(AppContext);
  const [visibleNet, setVisibleNet] = useState("facebook");
  //console.log("pages", state.pages);
  //console.log("currentpage", state.currentPage);

  const location = useLocation();
  const { pathname } = useLocation();
  //console.log(pathname);
  //console.log("location", location);
  let tab = location && location.pathname.match(/\/([^/\d]+)$/);
  // if (tab) tab = tab[0]
  //console.log("tab", tab);

  const sideBarCollapse = () => {
    setState((s) => {
      return { ...s, sideBarVisible: !state.sideBarVisible };
    });
  };

  const Menus = [
    { title: "test", src: "Chart_fill" },
    { title: "test", src: "Chat" },
    { title: "test", src: "User", gap: true },
    { title: "test ", src: "Calendar" },
    { title: "test", src: "Search" },
    { title: "test", src: "Chart" },
    { title: "test ", src: "Folder", gap: true },
    { title: "test", src: "Setting" },
  ];

  const navigate = useNavigate();

  const onSelectPage = (page) => {
    socialPage = page.page.id;
    setState((s) => {
      return { ...s, currentPage: page };
    });

    if (pathname.includes("planification")) {
      navigate(`/planification/${page.page_id}`);
    } else {
      navigate(`/reporting/${page.page_id}`);
    }
  };

  let pagesByType = {};
  if (state.pages) {
    state.pages.forEach(
      (el) =>
        (pagesByType[el.social_type] = [
          ...(pagesByType[el.social_type] || []),
          el,
        ])
    );
  }

  //console.log("pages", state.pages);
  //console.log("pageBytype", pagesByType);
  return (
    <div
      className={` ${
        state.sideBarVisible ? "w-72" : "w-16 "
      } bg-white h-screen border-r  pt-7  duration-300 overflow-x-auto drawer-bar relative`}
    >
      {/* <img
        src={arrow}
        className={`absolute cursor-pointer right-4 ${
          !state.sideBarVisible && "right-0"
        } top-9  w-5 border-white
           border-2 rounded-full  ${!state.sideBarVisible && "rotate-180"}`}
        onClick={() => sideBarCollapse()}
      /> */}
      <Link to="/welcome">
        <div className="flex items-center mx-5">
          <IconLogo
            className={`${
              state.sideBarVisible && "rotate-[360deg]"
            } cursor-pointer duration-500 `}
          />
          <TextLogo className={`${!state.sideBarVisible && "hidden"} ml-4`} />
        </div>
      </Link>
      {/* <ul className="pt-6 mt-11 flex flex-col gap-6">
        {Menus.map((Menu, index) => (
          <li
            key={index}
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${
              index === 0 && "bg-light-white"
            } `}
          >
            <img src={`./src/images/${Menu.src}.png`} />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              {Menu.title}
            </span>
          </li>
        ))}
      </ul> */}
      {!pathname.includes("planification")
        ? ["facebook", "instagram", "linkedin", "tiktok", "youtube" ,"twitter"].map(
            (el) => (
              <PagesList
                visible={visibleNet == el}
                onVisibleChange={(n) => n && setVisibleNet(el)}
                tab={pathname}
                key={el}
                type={el}
                pages={pagesByType[el]}
                onSelectPage={onSelectPage}
              />
            )
          )
        : ["facebook" /*, "instagram", "linkedin", "twitter"*/].map((el) => (
            <PagesList
              visible={visibleNet == el}
              onVisibleChange={(n) => n && setVisibleNet(el)}
              tab={pathname}
              key={el}
              type={el}
              pages={pagesByType[el]}
              onSelectPage={onSelectPage}
            />
          ))}

      {/* <h1
        className={`${
          !state.sideBarVisible && "hidden"
        } ml-5 text-blue-500 items-center mt-40 mb-14 font-medium`}
      >
        Gérer mes pages +
      </h1> */}

      {state.sideBarVisible && (
        //<Link to="/pages" className="hide_el">
        <Link to="/pages">
          <h1
            className={`${
              !state.sideBarVisible && "hidden scale-200"
            } ml-5 text-blue-500  absolute bottom-0  mb-14 font-medium flex gap-2 items-center`}
          >
            {t("Options.page_management")}{" "}
            <span>
              <img src={plusCircleIcon} alt="" />
            </span>
          </h1>
        </Link>
      )}
      {!state.sideBarVisible && (
        <h1
          className={`${
            state.sideBarVisible && "hidden scale-200"
          }  text-blue-500 items-center mt-40 ml-6 absolute bottom-0  mb-14  font-medium`}
        >
          <Link to="/pages">
            <span>
              <img src={plusCircleIcon} alt="" />
            </span>
          </Link>
        </h1>
      )}
    </div>
    //   <div className="h-screen flex-1 p-7">
    //     <h1 className="text-2xl font-semibold ">Home Page</h1>
    //   </div>
  );
};
export default SideBar;
