import React, { useState } from 'react';
import { API } from "../../utils";

function UploadStorie() {

    const [urlPhoto, setUrlPhoto] = useState('');
    const [urlVideo, setUrlVideo] = useState('');
    const [photo, setPhoto] = useState(null);
    const [video, setVideo] = useState(null);

    const [isUrlPhoto, setIsUrlPhoto] = useState(false);
    const [isUrlVideo, setIsUrlVideo] = useState(false);
    const [description, setDescription] = useState('');

    const handleUrlChangePhoto = (e) => {
        setUrlPhoto(e.target.value); // Assurez-vous de mettre à jour la valeur correcte ici
        setPhoto(null); // Réinitialiser le fichier photo
        setIsUrlPhoto(true); // Basculer sur le mode URL
    };

    const handleFileChangePhoto = (e) => {
        setPhoto(e.target.files[0]);
        setUrlPhoto(''); // Réinitialiser l'URL si un fichier est sélectionné
        setIsUrlPhoto(false); // Basculer sur le mode upload
    };

    const handleSubmitPhoto = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('url', urlPhoto);
        formData.append('photo', photo);
        formData.append('description', description);

        try {
            const response = await API.planificates.uploadStoriePhoto(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading photo:', error);
        }
    };

    const handleFileChangeVideo = (e) => {
        setVideo(e.target.files[0]);
    };

    const handleUrlChangeVideo = (e) => {
        setUrlVideo(e.target.value);
        setVideo(null); // Réinitialiser le fichier si une URL est saisie
        setIsUrlVideo(true); // Basculer sur le mode URL
    };

    const handleSubmitVideo = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('url', urlVideo);
        formData.append('video', video);
        formData.append('description', description);

        try {
            const response = await API.planificates.uploadStorieVideo(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    return (
        <div>
            <h1>Upload Video</h1>
            <form onSubmit={handleSubmitVideo}>
                {isUrlVideo ? (
                    <input
                        type="text"
                        value={urlVideo}
                        onChange={handleUrlChangeVideo}
                        placeholder="Enter Video URL"
                    />
                ) : (
                    <input type="file" onChange={handleFileChangeVideo} />
                )}
                <button type="button" onClick={() => setIsUrlVideo(!isUrlVideo)}>
                    {isUrlVideo ? "Upload a Video" : "Use a Video URL"}
                </button>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
                <button type="submit">Upload</button>
            </form>
            <br/>
            <h1>Upload Photo</h1>
            <form onSubmit={handleSubmitPhoto}>
                {isUrlPhoto ? (
                    <input
                        type="text"
                        value={urlPhoto}
                        onChange={handleUrlChangePhoto}
                        placeholder="Enter Image URL"
                    />
                ) : (
                    <input type="file" onChange={handleFileChangePhoto} />
                )}
                <button type="button" onClick={() => setIsUrlPhoto(!isUrlPhoto)}>
                    {isUrlPhoto ? "Upload a Photo" : "Use an Image URL"}
                </button>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadStorie;
