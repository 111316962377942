import React, { useState } from 'react';
import { API } from "../../utils";
import { InputTextarea } from 'primereact/inputtextarea';

function UploadFeed() {
    const [status, setStatus] = useState('');
    const [link, setLink] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('link', link);
        formData.append('status', status);
        
        try {
            const response = await API.planificates.getFeed(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error pusblishing:', error);
        }
    };
    
    return (
        <div>
            <h1>Upload Feed</h1>
            <form onSubmit={handleSubmit}>
                <InputTextarea rows={5} cols={30} value={status} onChange={(e) => setStatus(e.target.value)} autoResize />

                <input
                    type="text"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Link"
                />
                <button type="submit">Publier</button>
            </form>
        </div>
    );
}

export default UploadFeed;
