import React, { useState } from 'react';
import { API } from "../../utils";

function UploadReel() {
    const [url, setUrl] = useState('');
    const [video, setVideo] = useState(null);
    const [description, setDescription] = useState('');
    const [thumbnail, setThumbnail] = useState(null); // Nouvel état pour le thumbnail

    const handleVideoChange = (e) => {
        setUrl('');
        setVideo(e.target.files[0]);
    };

    const handleThumbnailChange = (e) => {
        setThumbnail(e.target.files[0]); // Gestion du fichier thumbnail
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('url', url);
        formData.append('video', video);
        formData.append('description', description);
        if (thumbnail) {
            formData.append('thumbnail', thumbnail); // Ajouter le thumbnail
        }

        try {
            const response = await API.planificates.getReel(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    return (
        <div>
            <h1>Upload Reel</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Video:</label>
                    <input type="file" onChange={handleVideoChange} accept="video/*" />
                </div>
                <div>
                    <label>Thumbnail:</label>
                    <input type="file" onChange={handleThumbnailChange} accept="image/*" />
                </div>
                <div>
                    <label>URL:</label>
                    <input
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Url"
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                    />
                </div>
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadReel;
