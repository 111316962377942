import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import avatarIcon from "../images/images/avatar.svg";
import globeIcon from "../images/svg/Globe.svg";
import { ReactComponent as AvatarArrow } from "../images/images/avatarArrow.svg";

import moment from "moment";
import dayjs from "dayjs";

import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import { TabMenu } from "primereact/tabmenu";
import { AppContext } from "../utils/Context";

import 'primeicons/primeicons.css';
import '../../src/mystyle.css';


import { Menu, Transition } from "@headlessui/react";

import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  Redirect,
} from "react-router-dom";

import API from "../utils/Api";

import ReportingNavigation from "../cmp/Reporting/Navigation";
import PlanificationNavigation from "../cmp/Planification/Navigation";

const Header = (props) => {

  const [state, setState] = React.useContext(AppContext);
  let userData = state.userData || {};
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const location = useLocation();
  const { t, i18n } = useTranslation();
  //detect language

  let userLang = navigator.language || navigator.userLanguage;
  // console.log("userlang", userLang);

  let l = userLang.includes("fr")
    ? localStorage.getItem("Lang") || "FR"
    : localStorage.getItem("Lang") || "EN";
  //console.log("l", l);

  /*const changeLang = () => {
    if (l == "FR") {
      localStorage.setItem("Lang", "EN");
      i18n.changeLanguage("en");
    }
    if (l == "EN") {
      localStorage.setItem("Lang", "FR");
      i18n.changeLanguage("fr");
    }
  };*/
  const changeLang = () => {
    const currentLang = localStorage.getItem("Lang") || "EN";
    const newLang = currentLang === "en" ? "fr" : "en"; // Inversion de FR et EN
    localStorage.setItem("Lang", newLang);
    i18n.changeLanguage(newLang);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const StyledBadge = styled(Badge)(({ theme, isCurrentLang }) => ({
    "& .MuiBadge-badge": {
      right: "25px",
      top: "20px",
      border: `1px solid ${theme.palette.background.paper}`,
      background: isCurrentLang
        ? "rgb(105 65 198 / var(--tw-text-opacity))"
        : "rgb(249 245 255 / var(--tw-bg-opacity))",
      color: isCurrentLang
        ? "#fff"
        : "rgb(105 65 198 / var(--tw-text-opacity))",
      padding: "12px",
    },
  }));

  let tab = location && location.pathname.match(/\/([^/\d]+)$/);
  if (tab) tab = tab[1];
  if (!tab) tab = "brand";
  const currentPage = state?.currentPage?.page_id;

  useEffect(() => {
    setState({ ...state, searchTerm: searchTerm });
    // setState({
    //   ...state,
    //   reportingPeriod: [dayjs().subtract(1, "year").toDate(), dayjs().toDate()],
    // });
  }, []);

  // const history = useNavigate();
  // const [urlTab, setUrlTab] = useState("");

  // history.listen((location, action) => {
  //   console.log(location.pathname);
  //   setUrlTab(location.pathname);
  //   // console.log(action);
  // });

  const { pathname } = useLocation();

  const navigationHeader = () => {
    if (pathname.includes("planification")) {
      return <PlanificationNavigation />;
    } else {
      return <ReportingNavigation />;
    }
  };

  return (
    <nav className="bg-white py-2 border-b">
      <div className="flex justify-between items-center">
        {navigationHeader()}

        <div className="flex flex-row items-center float-right z-50">
          {showSearch && (
            <span className="p-input-icon-left ml-8  border-asap-purple dark:border-asap-white invisible md:visible hide_el">
              <i className="pi pi-search text-asap-purple dark:text-asap-white" />
              <InputText
                className="text-asap-purple mr-4 dark:text-asap-white font-CityOfEmber placeholder-asap-purple dark:placeholder-asap-white text-sm  "
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search page"
              />
            </span>
          )}
          <i
            onClick={() => setShowSearch(!showSearch)}
            className="pi pi-search mr-3 text-asap-purple dark:text-asap-white relative cursor-pointer invisible md:visible hide_el"
            style={{ fontSize: "1.5rem" }}
          ></i>

            {i18n.language !== "en" && (
              <label className="cursor-pointer flex items-center text-black">
                <img src={globeIcon} />
                <button className="border-black lang_btn px-4 py-3 font-semibold text-[16px] cursor-pointer text-[#475467]" onClick={() => changeLanguage("en")}>En</button>
              </label>
            )}
            {i18n.language !== "fr" && (
              <label className="cursor-pointer flex items-center text-black">
                <img src={globeIcon} />
                <button className="border-black lang_btn px-4 py-3 font-semibold text-[16px] cursor-pointer text-[#475467]" onClick={() => changeLanguage("fr")}>Fr</button>
              </label>
            )}
    
          {/* <StyledBadge
            onClick={() => changeLang("fr")}
            badgeContent={"FR"}
            isCurrentLang={l === "fr"}
            className="cursor-pointer text-white font-bold text-lg font-Montserrat"
          >
            <LanguageIcon
              fontSize="large"
              className="text-diggow-primary ml-4"
            />
          </StyledBadge>
          <StyledBadge
            onClick={() => changeLang("en")}
            badgeContent={"EN"}
            isCurrentLang={l === "en"}
            className="cursor-pointer text-white font-bold text-lg font-Montserrat mr-5"
          >
            <LanguageIcon
              fontSize="large"
              className="text-diggow-primary ml-6"
            />
          </StyledBadge> */}

          <Menu>
            {({ open }) => (
              <>
                <Menu.Button className="z-50 inline-flex">
                  <div className="flex-row flex items-center">
                    <Avatar
                      image={userData.picture ? userData.picture : avatarIcon}
                      className=" w-12 h-12 "
                      shape="circle"
                    />
                    <h1 className="text-asap-blue dark:text-asap-white  mr-1 xl:mr-2   lg:ml-1 xl:text-base text-xs">
                      {userData?.name}
                    </h1>
                    <AvatarArrow className=" xl:mr-4 dark:text-asap-white text-asap-blue mt-1" />
                  </div>
                </Menu.Button>

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                >
                  <Menu.Items
                    static
                    className="avatar-menu-item absolute mt-8 z-50 right-3  w-40  origin-top-right bg-white  border border-asap-gray-900 divide-y divide-gray-100 rounded-xl shadow-lg outline-none"
                  >
                    <div className="py-1 text-left">
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/dashboard">
                            <p
                              className={`${
                                active
                                  ? "bg-white text-asap-blue  "
                                  : "text-asap-text"
                              } flex justify-between w-full px-4 py-1 text-sm leading-5 text-left font-medium font-CityOfEmber`}
                            >
                              {t("Options.Tableau de bord")}
                            </p>
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/profile">
                            <p
                              className={`${
                                active
                                  ? "bg-white text-asap-blue "
                                  : "text-asap-text"
                              } flex justify-between w-full px-4 py-1 text-sm leading-5 text-left font-medium font-CityOfEmber`}
                            >
                              {t("Options.Mon profile")}
                            </p>
                          </Link>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                        {({ active }) => (
                          <Link to={`/mot_cle/${currentPage}`}>
                            <p
                              className={`${
                                active
                                  ? "bg-white text-asap-blue "
                                  : "text-asap-text"
                              } flex justify-between w-full px-4 py-1 text-sm leading-5 text-left font-medium font-CityOfEmber`}
                            >
                              {"Mot-clé"}
                            </p>
                          </Link>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          <Link to="/abonnements">
                            <p
                              className={`${
                                active
                                  ? "bg-asap-gray text-asap-blue "
                                  : "text-asap-text"
                              } flex justify-between w-full px-4 py-1 text-sm leading-5 text-left font-medium font-CityOfEmber`}
                            >
                              {t("Options.Subsriptions")}
                            </p>
                          </Link>
                        )}
                      </Menu.Item>
                      {/* <Menu.Item>
                         {({ active }) => (
                           <p
                             onClick={() => handleHelp()}
                             className={`${
                               active
                                 ? "bg-asap-gray text-asap-blue "
                                 : "text-asap-text"
                             } flex justify-between w-full px-4 py-1 z-50 text-sm leading-5 text-left font-medium font-CityOfEmber cursor-pointer`}
                           >
                             Aide
                           </p>
                         )}
                       </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            onClick={API.user.closeSession}
                            className={`${
                              active
                                ? "bg-white text-asap-blue "
                                : "text-asap-text"
                            } flex justify-between w-full px-4 py-1 z-50 text-sm leading-5 text-left font-medium font-CityOfEmber cursor-pointer`}
                          >
                            {t("Options.Se deconnecter")}
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Header;
