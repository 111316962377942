import React, { useState } from 'react';
import { API } from "../../utils";

function UploadYtb(props) {

    console.log('props', props);
    

    const [url, setUrl] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('url', url);

        try {
            const response = await API.planificates.uploadYtb(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    return (
        <div>
            <h1>Upload YTB</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Url"
                />
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadYtb;
